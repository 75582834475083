import React from "react";
import "../config/i18n";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";

const Faq = () => {
  
const { t, i18n } = useTranslation();
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <><Link to="https://profile.thedigi.app">
            <button className="btn btn btn-dark-digi btn-sm-2 rounded-8 h-50 font-size-5">
            <i className="fa fa-lock mr-9 font-size-5 " />   {t("login")}  
          </button>
          </Link>
            </>
          ),
          footerStyle: "style6",
        }}
      >
        <div className="pt-23 pt-md-26 pt-lg-31 pb-13 pb-md-18 pb-lg-23">
          <div className="container">
            {/* Section Title */}
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9">
                <div className="text-center mb-13 mb-lg-18">
                  <h2 className="font-size-11 mb-7">
                    {t("faq_title")}
                  </h2>                  
                </div>
              </div>
            </div>
            {/* End Section Title */}
            {/* Faqs */}
            {/* Faqs */}
            <div className="row justify-content-center">
              {/* Single Faq */}
              {/* <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-digi circle-28 text-white mt-1">
                      <i className="fa fa-question text-dark-digi" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                    {t("faq_heading1")}
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    {t("faq_paragraph1")}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* End Single Faq */}
              {/* Single Faq */}
              {/* <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-digi circle-28 text-white mt-1">
                      <i className="fa fa-question text-dark-digi" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                    {t("faq_heading2")}
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    {t("faq_paragraph2")}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-digi circle-28 text-white mt-1">
                      <i className="fa fa-question text-dark-digi" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                    {t("faq_heading3")}
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    {t("faq_paragraph3")}
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              
            </div>
            {/* Button  */}
            <div className="text-center pt-lg-3">
              <p className="font-size-6 mb-0">
              {t("faq_subtitle")}
              <p><Link to="/contact"><a
                  className="btn btn-golden-yellow h-60 w-40 rounded-4"
                  >
                    {t("faq_button")}
                </a></Link>
              </p>
              </p>
            </div>
            {/* Button End */}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Faq;
